import Camera from "@/components/SVG/Camera";
import { updateOffCanvasView } from "@/store/slices/offCanvas";
import { useDispatch } from "react-redux";

const AskDoubt = () => {
  const dispatch = useDispatch();

  return (
    <div className="flex justify-center items-center text-2xl mr-12">
      <div className="absolute bg-white rounded-full w-[4.7rem] h-[4.7rem] border-gray-200 border-2"></div>
      <circle className="h-12 w-12 bg-accent animate-pingShortReduced absolute rounded-full flex justify-center items-center"></circle>
      <circle className="h-[3.7rem] w-[3.7rem] bg-accent animate-pingShortReduced absolute opacity-70 rounded-full flex justify-center items-center"></circle>
      <button onClick={() => dispatch(updateOffCanvasView({ name: "CAMERA" }))} className={`absolute bg-accent text-white font-large h-12 w-12 justify-center flex flex-col items-center cursor-pointer rounded-full transition-all duration-1000 ease-in-out px-2`}>
        <Camera className="ask-doubt text-xl" />
      </button>
    </div>
  );
}

export default AskDoubt;
