import Image from "next/image";
import LinkWrapper from "@/components/LinkWrapper";

const Pills = (props: {
  items: {
    id?: string;
    text: string;
    href?: string;
    image?: string;
    before?: JSX.Element;
    after?: JSX.Element;
    className?: string;
    onClick?: () => void;
    type: "pillBorder" | "pillShadow";
    selected?: boolean
  }[];
  overflow?: boolean;
  className?: string
}) => {
  return (
    <div className={`flex gap-2 lg:gap-4 ${props.overflow ? "overflow-x-auto" : "flex-wrap"} ${props.className || ""}`}>
      {
        props.items.map((item, index) => {
          if (item.onClick) {
            return <button id={item.id ? `tag-${item.id}` : ""} key={index.toString()} onClick={item.onClick} className={`pill inline-flex gap-2 justify-center items-center w-max shrink-0 ${item.className ?? ""} ${item.type} ${item.selected ? "selected" : ""}`}>
              {
                item.image && <Image className="rounded-full h-5 w-5" src={item.image} width={16} height={16} alt={item.text} />
              }
              <span className="flex items-center gap-1">{item.before}{item.text}{item.after}</span>
            </button>
          }
          if (item.href) {
            return <LinkWrapper id={item.id ? `tag-${item.id}` : ""} key={index.toString()} href={item.href} className={`pill link py-2 clickable inline-flex gap-2 justify-center items-center w-max shrink-0 ${item.className ?? ""} ${item.type} ${item.selected ? "selected" : ""}`}>
              {
                item.image && <Image className="rounded-full h-5 w-5" src={item.image} width={16} height={16} alt={item.text} />
              }
              <span className="flex items-center gap-1">{item.before}{item.text}{item.after}</span>
            </LinkWrapper>
          }
          return <span id={item.id ? `tag-${item.id}` : ""} key={index.toString()} className={`pill inline-flex gap-2 justify-center items-center w-max shrink-0 ${item.className ?? ""} ${item.type} ${item.selected ? "selected" : ""}`} onClick={item.onClick}>
            {
              item.image && <Image className="rounded-full h-5 w-5" src={item.image} width={16} height={16} alt={item.text} />
            }
            <span className="flex items-center gap-1">{item.before}{item.text}{item.after}</span>
          </span>
        })
      }
    </div>
  );
}

export default Pills;