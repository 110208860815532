import { autoSuggest } from "@/helpers/api/service/search";
import debounce from "lodash/debounce";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import AutoComplete from "../AutoComplete";
import FaSearch from "../SVG/Fontawesome/FaSearch";
import Camera from "../Header/Camera";
import InputPlaceholder from "../Header/InputPlaceholder";
import VoiceSearchIcon from "../Header/VoiceSearchIcon";
import { getMatches } from "@/helpers/api/service/ask";
import { getUUID } from "@/utils/auth";
import { useDispatch } from "react-redux";
import { offCanvasInitialState, updateOffCanvasView, updateOffCanvasViewPayload } from "@/store/slices/offCanvas";

const SearchHome = () => {
  const [inFocus, setInFocus] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<any[]>();
  const inputRef = useRef<HTMLInputElement>(null);

  const router = useRouter();
  const dispatch = useDispatch();

  const debouncedResults = debounce(async () => {
    if (!inputRef.current?.value) {
      return;
    }
    const {
      data: { suggestions },
    } = await autoSuggest(inputRef.current.value);
    setSuggestions(suggestions);
  }, 300);

  const getIntermediateResults = () => {
    if (!inputRef.current?.value) {
      return setSuggestions([]);
    }
    debouncedResults();
  };

  const search = async (event: any) => {
    if (!inputRef.current?.value) return;
    if (!(event.keyCode === 13 || event.type === "click")) return;
    const { data } = await getMatches(getUUID(), undefined, undefined, inputRef.current?.value);
    if (!data) return;
    dispatch(updateOffCanvasView({
      name: "CAMERA",
      data: {
        CAMERA: {
          ...offCanvasInitialState.data.CAMERA,
          text: inputRef.current?.value,
          questionId: data.question_id,
          matchedQuestions: data.matched_questions ?? [],
        },
      }
    }));
  };

  const onFocus = () => {
    setTimeout(() => {
      setInFocus(true);
    }, 300);
  };

  const onBlur = () => {
    setTimeout(() => {
      setInFocus(false);
    }, 300);
  };

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.onfocus = onFocus;
    inputRef.current.onblur = onBlur;
  }, [inputRef.current]);

  useEffect(() => {
    if (!router.query.q || typeof router.query.q !== "string") {
      return;
    }
    if (inputRef.current) {
      inputRef.current.value = router.query.q;
    }
  }, [router.query.q]);

  return (
    <span className="w-full lg:w-max m-auto text-xl relative inline-flex cursor-pointer items-center justify-center p-0 rounded-full flex-col">
      <div className="relative flex items-center w-full lg:w-[auto]">
        <span className="absolute top-5 z-[99] left-5">
          <button onClick={search} id="hd-search" name='hd-search'>
            <FaSearch className="w-5 h-5" />
          </button>
        </span>
        <InputPlaceholder
          inputRef={inputRef}
          className=" h-16 border-2 font-medium border-border-mute-active focus:border-border-brand-active p-2 relative right-0 w-full lg:w-[70vw] m-auto  transition-all delay-300 rounded-full text-sm  pl-12 pr-16 bg-secondary"
          onChange={getIntermediateResults}
          onKeyDown={search}
        />
        <span className="absolute right-4 fill-body flex items-center space-x-4">
          <VoiceSearchIcon />
          <span id="hd-camera" className="inline">
            <Camera color="fill-inherit" />
          </span>
        </span>
      </div>
      {inFocus && (
        <div className="w-full mx-auto bg-white overflow-auto  absolute top-16 text-sm tracking-wider rounded-lg z-20">
          <AutoComplete inputRef={inputRef} suggestions={suggestions} />
        </div>
      )}
    </span>
  );
};

export default SearchHome;
