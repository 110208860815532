import { nodeBeautifier } from "@/utils/html";
import { INode } from "./interfaces";
import Image from "next/image";
import Shimmer from "@/utils/shimmer";
import { ITableCell } from "../Table/interfaces";
import { createElement } from "react";
import SearchHome from "../Search/SearchHome";
import AskDoubt from "../PageOverlay/icons/AskDoubt";
import VideoPlayer from "../VideoPlayer";
import dynamic from "next/dynamic";
import RelatedLinks from "../Calculator/RelatedLinks";
import Results from "../Results/Results";
import TopIconList from "../TopIcons";
import TopCards from "../TopCards";
import DownloadAppV2 from "../DownloadApp/DownloadAppV2";
import { CourseCardList } from "../CourseWidget/CourseCardList";
import StatsV2 from "../Stats/StatsV2";
import BookList from "../Book";
import BookListV2, { BookListV2Item } from "../Book/BookV2";
import AnchorElement from "./AnchorElement";
import Pills from "../Pills";

const StaticCarousel = dynamic(() => import("@/components/Carousel/StaticCarousel"));
const Table = dynamic(() => import("@/components/Table"));
const Tabs = dynamic(() => import("../Tabs/Tabs"));
const Accordion = dynamic(() => import("../Accordion/Accordion"))

const Content = (props: INode) => {

  const className = [nodeBeautifier(props.nodeName), props.props?.className]
    .filter(Boolean)
    .join(" ");

  const innerHTML = (props.innerHTML || "").toString()
    .replace(/\r/g, "")
    .replace(/\t/g, "");
  // .replace(/\n/g, "")


  switch (props.nodeName?.toUpperCase()) {
    case "IMG":
      return (
        <Image
          {...(props.props as { src: string; alt: string })}
          className={className}
          width={600}
          height={400}
          placeholder="blur"
          blurDataURL={Shimmer(600, 400)}
        />
      );
    case "TABLE":
      const tableRows = innerHTML.includes("tbody")
        ? innerHTML
          .split(/<tbody>(.*?)<\/tbody>/g)
          .filter(Boolean)[0]
          .split(/<tr>(.*?)<\/tr>|<th>(.*?)<\/th>/g)
          .filter(Boolean)
        : innerHTML.split(/<tr>(.*?)<\/tr>|<th>(.*?)<\/th>/g).filter(Boolean);
      const tableCells = tableRows.map((row) =>
        row.split(/<td>(.*?)<\/td>/g).filter(Boolean)
      );

      const header: ITableCell[] = tableCells[0].map((cell) => ({
        text: cell,
        className: nodeBeautifier("TH-TD"),
      }));
      const rows: ITableCell[][] = tableCells.slice(1).map((row) =>
        row.map((cell) => ({
          text: cell,
          className: nodeBeautifier("TR-TD"),
        }))
      );

      return <Table header={header} rows={rows} className={className} />;
    case "BANNER_AD":
      return <></>;
    case "CAMERA":
      return <AskDoubt />;
    case "SEARCH":
      return <SearchHome />;
    case "TABS":
      const dataProps:any = {};
      for(let i = 0; i < props?.meta?.data?.tabs?.length; i++){
        dataProps[props.meta?.data?.tabs?.[i]] = props.meta?.data?.items[i];
      }
      if(props.meta?.data.contentType){
        dataProps.contentType = props.meta?.data.contentType;
      }
      return <Tabs data={dataProps} className={className} />
    case "ACCORDION":
      return <Accordion items={props?.meta?.data?.items} parentClassName="accordion-content"/>
    case "CAROUSEL":
      return <StaticCarousel items={props?.meta?.data.items} showArrows={false}/>
    case "TOP-ICONS":
      return <TopIconList items={props.props?.items} />
    case "RESULTS":
      return <Results items={props.props?.items} />
    case "TOP-CARDS":
      return <TopCards items={props.props?.items} />
    case "DOWNLOAD-APP":
      return <DownloadAppV2 />
    case "COURSE-LIST":
      return <CourseCardList data={props.props?.items} />
    case "STATS":
      return <StatsV2 items={props.props?.items} />
    case "BOOKS":
      return <BookList items={props.props?.items} />
    case "BOOKS_V2":
      return <BookListV2 {...props.props as any} />
    case "BOOKS_V2_ITEM":
      return <BookListV2Item {...props.props as any} />
    case "VIDEO":
      return <VideoPlayer {...props.props as any} />
    case "A":
      return <AnchorElement {...props} className={className} innerHTML={innerHTML}/>
    case "RELATED_LINKS":
      return <RelatedLinks title={props.props?.title} items={props.props?.items} />;
    case "PILLS": 
      return <Pills items={props.props?.items} className={className} />
    default:
      return createElement(props.nodeName?.toLowerCase() || "div", {
        ...props.props,
        className,
        dangerouslySetInnerHTML: { __html: innerHTML },
      });
  }
};

export default Content;