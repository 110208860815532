const Camera = (props: { className?: string }) => {
  return (
    <svg className={props.className ?? ""} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
      <path d="M44.0208 39.3373H43.9956V39.4373C43.9956 39.8623 43.8308 40.239 43.5476 40.5222C43.2645 40.8053 42.8878 40.9702 42.4628 40.9702H21.9643C21.5392 40.9702 21.1626 40.8053 20.8794 40.5222C20.5963 40.239 20.4314 39.8623 20.4314 39.4373V26.9272C20.4314 26.5021 20.5963 26.1255 20.8794 25.8423C21.1626 25.5592 21.5392 25.3943 21.9643 25.3943H26.8126C27.2446 25.3943 27.5908 25.0481 27.5908 24.6161V23.8876C27.5908 23.6353 27.6824 23.4103 27.8379 23.2549C27.9933 23.0995 28.2183 23.0078 28.4705 23.0078H35.9816C36.2339 23.0078 36.4589 23.0995 36.6143 23.2549C36.7697 23.4103 36.8614 23.6353 36.8614 23.8876V24.6161C36.8614 25.0481 37.2076 25.3943 37.6396 25.3943H42.4879C42.9129 25.3943 43.2896 25.5592 43.5728 25.8423C43.8559 26.1255 44.0208 26.5021 44.0208 26.9272V39.3373ZM44.6186 24.7714C44.0723 24.2001 43.3184 23.8629 42.4628 23.8629H38.3923C38.3794 23.2278 38.1206 22.6195 37.6841 22.208C37.239 21.7634 36.6364 21.5016 35.9816 21.5016H28.4454C27.7656 21.5016 27.1624 21.7634 26.7167 22.2091C26.2828 22.6431 26.0226 23.2269 26.0097 23.8629H21.9643C21.1083 23.8629 20.354 24.2004 19.8077 24.7723C19.2636 25.3168 18.9 26.0949 18.9 26.9272V39.4373C18.9 40.2933 19.2375 41.0476 19.8093 41.5939C20.3539 42.138 21.132 42.5016 21.9643 42.5016H42.4628C43.3188 42.5016 44.0731 42.1641 44.6194 41.5922C45.1635 41.0477 45.5271 40.2696 45.5271 39.4373V26.9272C45.5271 26.0716 45.1899 25.3176 44.6186 24.7714Z" fill="white" stroke="white" stroke-width="0.2" />
      <path d="M36.9341 32.2048L36.9342 32.2067C36.9587 33.5082 36.4187 34.6883 35.5571 35.5501C34.695 36.3873 33.5139 36.928 32.211 36.928C30.908 36.928 29.7269 36.3873 28.8648 35.5501C28.0042 34.6892 27.4878 33.5089 27.4878 32.2048C27.4878 30.9019 28.0285 29.7207 28.8658 28.8586C29.7266 27.9981 30.9069 27.4816 32.211 27.4816C33.5139 27.4816 34.695 28.0223 35.5572 28.8596C36.4177 29.7204 36.9341 30.9007 36.9341 32.2048ZM32.211 25.9C30.4751 25.9 28.8925 26.6148 27.7692 27.738C26.6207 28.8866 25.9312 30.4441 25.9312 32.1797C25.9312 33.9156 26.646 35.4982 27.7692 36.6214C28.9178 37.77 30.4754 38.4594 32.211 38.4594C33.9469 38.4594 35.5295 37.7447 36.6527 36.6214C37.8013 35.4729 38.4907 33.9153 38.4907 32.1797C38.4907 30.4438 37.7759 28.8612 36.6527 27.738C35.5295 26.6148 33.9469 25.9 32.211 25.9Z" fill="white" stroke="white" stroke-width="0.2" />
      <path d="M41.1567 29.5384C41.8917 29.5384 42.4876 28.9425 42.4876 28.2075C42.4876 27.4724 41.8917 26.8766 41.1567 26.8766C40.4217 26.8766 39.8258 27.4724 39.8258 28.2075C39.8258 28.9425 40.4217 29.5384 41.1567 29.5384Z" fill="white" stroke="white" stroke-width="0.2" />
    </svg>
  );
};

export default Camera;